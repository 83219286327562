/* The following styles get applied both on the front of your site and in the editor. */
.wp-block-gca-sub-heading {
  &.has-underline {
    display: grid;
    gap: var(--wp--preset--spacing--2-xs, 2rem);

    &::after {
      content: '';
      display: block;
      width: 3rem;
      height: 1px;
      background-color: currentColor;
    }
  }

  &.aligncenter {
    text-align: center;

    &::after {
      margin: 0 auto;
    }
  }

  &.alignright {
    text-align: right;

    &::after {
      margin-left: auto;
    }
  }

  .wp-block-heading {
    @apply font-bold;
  }
}
